import React, { useEffect, useRef, useContext } from 'react'
import { MainContext } from "context/context"

export default ({
   heading,
   copy,
   backgroundImage,
}) => {
	const {
        addInvertedHeaderSection
    } = useContext(MainContext)

    const elementRef = useRef(null)
    
    useEffect(() => {
        if(elementRef && elementRef.current){
            addInvertedHeaderSection(elementRef.current)
        }
    }, [elementRef])

    return (
        <div ref={ref => elementRef.current = ref}>
            <div className="hero smaller" style={{ backgroundImage: `url(${ backgroundImage && backgroundImage.mediaItemUrl })` }}>
                <div className="container">
                    <div className="content">
                        <h1 dangerouslySetInnerHTML={{ __html: heading }} />
                        <p className="copy" dangerouslySetInnerHTML={{ __html: copy }} />
                    </div>
                </div>
            </div>
        </div>
    )
}