import React, { useRef, useContext } from 'react'
import Slider from 'components/slider'
import { useStaticQuery, graphql } from "gatsby"
import { MainContext } from "context/context"
import BlogGridPost from 'components/blogGridPost'
import { useIsMobile } from 'utils'

export default ({
    title
}) => {
    const sliderRef = useRef(null)

    const {
        catSelected
	} = useContext(MainContext)

    const isMobile = useIsMobile()

    const {nodes: posts } = useStaticQuery(graphql`
        query {
            siteFields {
                posts (
                    first: 50
                ) {
                    nodes {
                        date
                        title
                        content
                        categories {
                            nodes {
                                name
                            }
                        }
                        featuredImage {
                            mediaItemUrl
                        }
                        link
                        postFields {
                            featuredPost
                        }
                    }
                }
            }
        }
    `).siteFields.posts

    let _nonFeaturedPosts = []

    {posts.map(post => {
        if (post.postFields.featuredPost === null) {
            _nonFeaturedPosts.push(post)
        }
    })}

    const flickityOptions = {
        groupCells: true,
        prevNextButtons: false,
        pageDots: false,
        cellAlign: 'left',
    }

    return (
        <div className={`recent-posts ${catSelected !== '' ? 'hide-slider' : ''}`}>
            <div className="container">
                <div className="heading-row">
                    {title &&
                    <>
                    <h3 dangerouslySetInnerHTML={{ __html: title }} />
                    <div />
                    </>
                    }
                </div>
                <div className="columns">
                    {!isMobile ?
                    <div className="slider-container">
                        <Slider
                            className={'carousel'}
                            options={flickityOptions}
                            flickityRef={ref => sliderRef.current = ref}
                        >
                            {_nonFeaturedPosts.map((post, index) => {
                                return (
                                    <BlogGridPost post={ post } key={ index } />
                                )
                            })}
                        </Slider>
                    </div>
                    :
                    <div className="mobile-grid">
                        {_nonFeaturedPosts.map((post, index) => {
                            return (
                                <BlogGridPost post={ post } key={ index } />
                            )
                        })}
                    </div>
                    }
                </div>
            </div>
        </div>
    )
}
