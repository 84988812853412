import React, { useEffect, useRef, useState, useContext } from 'react'
import { isEqual, throttle } from 'lodash'
import { MainContext } from "context/context"

import Hero from 'components/hero'
import Showcase from 'components/showcase'
import Instagram from 'components/instagram'
import InstagramHashtag from 'components/instagramHashtag'
import InteractiveMap from 'components/interactiveMap'
import ShareTheLove from 'components/shareTheLove'
import ResourcesGrid from 'components/resourcesGrid'
import HeroRegular from 'components/heroRegular'
import FullText from 'components/fullText'
import FeaturedSlider from 'components/featuredSlider'
import RecentPosts from 'components/recentPosts'
import BlogGrid from 'components/blogGrid'
import PostsFilter from 'components/postsFilter'
import NewsScroll from 'components/newsScroll'
import Copy from 'components/copy'
import Images from 'components/images'
import Quote from 'components/quote'
import SingleCta from 'components/singleCta'
import SingleVideo from 'components/singleVideo'
import Chapters from 'components/chapters'
import MainPostGrid from 'components/mainPostGrid'

const components = {
    Hero,
    Showcase,
    Instagram,
    InstagramHashtag,
    InteractiveMap,
    ShareTheLove,
    ResourcesGrid,
    HeroRegular,
    FullText,
    FeaturedSlider,
    RecentPosts,
    BlogGrid,
    PostsFilter,
    NewsScroll,
    Copy,
    Images,
    Quote,
    SingleCta,
    SingleVideo,
    Chapters,
    MainPostGrid,
}

export default ({ 
    content,
    slug = ''
}) => {

    const {
		componentRefs
    } = useContext(MainContext)
    
    const [visibleIndices, setVisibleIndices] = useState([])

    const visibleIndicesRef = useRef(visibleIndices)

    visibleIndicesRef.current = visibleIndices

    const handleScroll = () => {
        let _visibleIndices = []

        componentRefs.current.forEach((componentRef, index) => {
            const offset = componentRef.getBoundingClientRect().top

            let windowHeight = null
            
            if (typeof window != 'undefined') {
                windowHeight = window.innerHeight
            }

            if(offset < (windowHeight / 3 * 2)) {
                _visibleIndices.push(index) 
            }
        })

        if(!isEqual(_visibleIndices,visibleIndicesRef.current)) {
            setVisibleIndices(_visibleIndices)
        }
    }

    const debouncedHandleScroll = useRef(throttle(handleScroll, 50))

    useEffect(() => {
        const { current } = debouncedHandleScroll

        window.addEventListener('scroll', current)

        setTimeout(() => {
            current()
        },1700)

        return () => window.removeEventListener('scroll', current)
    }, [])

    const addComponent = (ref, index) => {
        if(ref){
            const _componentRefs =  [...componentRefs.current]

            _componentRefs[index] = ref

            componentRefs.current = _componentRefs
        }
    }

    return (
        <div className={slug}>
            { content.flexibleContent.map((row, index) => {
                if(!row){
                    return null
                }
                
                const Component = row.__typename.replace('WPGraphQL_Page_Flexiblecontent_FlexibleContent_','')
                const Tag = components[Component]

                const isScrolledIntoView = visibleIndices && visibleIndices.indexOf(index) !== -1

                let className = `${Component} ${ isScrolledIntoView ? 'scrolled-into-view' : '' }`

                return (
                    <section 
                        className={className} 
                        ref={ref => addComponent(ref, index)} 
                        key={index}
                        id={Component}
                    >
                        <Tag 
                            {...row} 
                            isScrolledIntoView={isScrolledIntoView}
                        />
                    </section>
                )
            }) }
        </div>
    )
}

