import React, { useContext, useEffect } from 'react'
import { MainContext } from "context/context"
import BlogGridPost from 'components/blogGridPost'

import { useLazyQuery } from '@apollo/client'
import { GET_POSTS_BY_CAT } from '../graphql/queries'

export default () => {
    const {
        catSelected
	} = useContext(MainContext)

    const [getPostsByCat, { data: postsData, loading }] = useLazyQuery(GET_POSTS_BY_CAT)

    useEffect(() => {
        getPostsByCat({
            variables: {
                category: catSelected
            },
        })
    },[getPostsByCat, catSelected])

    const posts = postsData && postsData.posts.nodes
    const _posts = catSelected ? ( posts && posts.length ) ? posts : [] : []

    const contClass = `BlogGrid-cont${ loading ? ' loading' : '' }${ catSelected === '' ? ' hide' : '' }`


    return (
        <div className={ contClass }>
            <div className="container">
                { _posts && _posts.length ?
                    <ul>
                        { _posts.map((post, index) => {
                            return (
                                <li key={ index }>
                                    <BlogGridPost post={ post } fullLink={ true } />
                                </li>
                            )
                        })}
                    </ul>
                :
                    <div dangerouslySetInnerHTML={{__html: 'Sorry, there were no posts returned.' }} />
                }
            </div>
        </div>
    )
}
