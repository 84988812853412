import React, { useContext, useRef, useEffect } from 'react'
import Button from 'components/button'
import 'vendor/flickity-as-nav-for/as-nav-for'
import 'flickity/css/flickity.css'
import Slider from 'components/slider'
import { MainContext } from 'context/context'

import MapIcon from '../images/map-icon.png'

import StickerA from '../images/stickers/a.png'
import StickerB from '../images/stickers/b.png'
import StickerC from '../images/stickers/c.png'

import { useIsMobile } from 'utils'

export default ({
    heading,
    copy,
    businesses,
    logo,
    headingAlignment
}) => {
	const {
        openModal,
    } = useContext(MainContext)

    const isMobile = useIsMobile()
    const sliderRef = useRef(null)

    const openStory = business => {
        if(business && business.title){
            openModal({
                type: 'BUSINESS',
                data: business
            })
        }
    }

    const businessesJsx = businesses.map(({ business }, index) => {

        const {
            title,
            locations,
            businessFields,
            featuredImage,
            specialties
        } = business

        return (
            <li 
                key={index}
                style={{
                    transitionDelay: (index/10 + 0.7)+'s'
                }}
            >
                <a onClick={() => {openStory(business)}}>
                    <div className="image">
                        <div className="image-inner" style={{backgroundImage: "url(" + featuredImage.mediaItemUrl + ")"}} />
                        <div className="logo-container">
                            <img className="logo"src={businessFields.logo && businessFields.logo.mediaItemUrl} alt={title}/>
                        </div>
                    </div>
                    <div className="info">
                        <div className={`title-row ${headingAlignment}`}>
                            <h3 className="title" dangerouslySetInnerHTML={{__html: title}} />
                            <div className={`location`}>
                                {headingAlignment === 'left' ?
                                    <>
                                        <div className="address">
                                            <img src={MapIcon} alt="Map icon" />
                                            <span className={headingAlignment}>
                                                {businessFields.address}
                                            </span>
                                        </div>
                                        <span className='type'>{specialties.nodes[0].name.split('&amp;').join('&')}</span>
                                    </>
                                :
                                    <>
                                        <img src={MapIcon} alt="Map icon" />
                                        <span>
                                            {locations.nodes[0].name}
                                        </span>
                                    </>
                                }
                            </div>
                        </div>
                        <p>
                            {businessFields.excerpt}
                        </p>
                        <Button
                            label={'View story'}
                        />
                    </div>
                </a>
            </li>
        )
    })

    useEffect(() => {
        setTimeout(() => {
            if(sliderRef.current) {
                sliderRef.resize() 
            }
        }, 1000);
    }, [sliderRef])

    return (
        <div className={`showcase ${headingAlignment}`}>
            <div className="container">
                {headingAlignment === 'left' ? 
                    <section className='alt-top-section'>
                        <h2 dangerouslySetInnerHTML={{__html: heading}} />
                        <div className={'copy'} dangerouslySetInnerHTML={{ __html: copy }} />
                    </section>
                :
                <div className="top-section">
                    <img src={StickerA} className={'sticker a'} alt=""/>
                    <img src={StickerB} className={'sticker b'} alt=""/>
                    <img src={StickerC} className={'sticker c'} alt=""/>
                    <div className="container">
                        {logo &&
                            <img className="background-logo" src={logo.mediaItemUrl} alt="Support local businesses"/>
                        }
                        <h2 dangerouslySetInnerHTML={{__html: heading}} />
                        <div className={'copy'} dangerouslySetInnerHTML={{ __html: copy }} />
                    </div>
                </div>
                }
                </div>
                <div className={`bottom-section no-rtl ${headingAlignment}`}>
                    <div className="container">
                        <ul className="grid">
                            {!isMobile || isMobile && headingAlignment === 'left'? 
                                businessesJsx 
                                : 
                                <Slider
                                    flickityRef={ref => sliderRef.current = ref}
                                    className={'carousel'}
                                    options={{
                                        wrapAround: true,
                                        groupCells: 1,
                                        prevNextButtons: false,
                                        pageDots: true,
                                        adaptiveHeight: true,
                                    }}
                                >
                                    { businessesJsx }
                                </Slider>                            
                            }
                        </ul>
                    </div>
                </div>
        </div>
    )
}