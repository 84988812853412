import React from 'react'
import Link from 'components/link'
import Button from 'components/button'
import { altFormatDate } from 'utils/index'

export default ({
    post,
    postNumber,
    button,
    fullLink,
    bg
}) => {
    const {
        title,
        date,
        categories,
        featuredImage,
        link,
        content
    } = post

    const blogGridPostContent = (
        <>
            <div className="featured-image-cont">
                <div className='featured-image' style={{ backgroundImage: `url(${ featuredImage && featuredImage.mediaItemUrl })`}}/>
            </div>
            <div className="content">
                <div className={`sub-content ${bg}`}>
                    {categories?.nodes[0] &&
                        <div className='specialty-types'>
                            {categories.nodes.map((type, index) => {
                                return (
                                    <h4 key={index} className='specialty' dangerouslySetInnerHTML={{__html: type.name.split('&amp;').join('&') }} />
                                )
                            })}
                        </div>
                    }
                </div>
                <h3 dangerouslySetInnerHTML={{__html: title }} data-number={ postNumber } className={ postNumber && "showPostNumber" } />
                <h4 dangerouslySetInnerHTML={{__html: altFormatDate(date) }} className='date' />
                <p dangerouslySetInnerHTML={{__html: content }} />
                <div className={ button ? "read-more read-more-button" : "read-more" }>
                    { fullLink ?
                        button ?
                            <Button label='Read more' />
                        :
                            <div dangerouslySetInnerHTML={{__html: 'Read more' }} className="link" />
                    :
                        button ?
                            <Button label='Read more' url={ link } />
                        :
                            <Link to={ link } dangerouslySetInnerHTML={{__html: 'Read more' }} className="link" />
                    }
                </div>
            </div>
        </>
    )

    return (
        <div className="blogGridPost">
            { fullLink ?
                <Link to={ link }>
                    { blogGridPostContent }
                </Link>
            :
                blogGridPostContent
            }
        </div>
    )
}
