import React, { useContext } from 'react'
import { MainContext } from "context/context"
import { useStaticQuery, graphql } from "gatsby"
import Select, { components } from 'react-select'

export default () => {
    const {
        catSelected,
        setCatSelected,
        isMobile
	} = useContext(MainContext)

	const { siteFields } = useStaticQuery(graphql`
        query {
            siteFields {
                categories {
                    nodes {
                        slug
                        name
                    }
                }
            }
        }
    `)

    const categories = siteFields.categories.nodes

    let selectOptions = []

    {categories.map(cat => {
        const {
            slug,
            name
        } = cat

        if (slug !== 'uncategorised') {
            selectOptions.push({
                value: slug,
                label: name.replace('&amp;', '&')
            })
        }
    })}

    let _categories = [{
        slug: '',
        name: 'Featured'
    }].concat(categories)

    _categories = _categories.filter(function(item) {
        return item.slug !== 'uncategorised'
    })

    const handleCategoryChange = (e) => {
        setCatSelected(e.value)
    }

    const selectStyle = {
        control: (base, state) => ({
            ...base,
            boxShadow: state.isFocused ? '#EC4834' : '#EC4834',
            border: state.is ? '1px solid #EC4834' : '1px solid #EC4834',
        })
      };

      const CaretDownIcon = () => {
        return <i className="fa fa-caret-down"></i>;
      };

      const DropdownIndicator = props => {
        return (
          <components.DropdownIndicator {...props}>
            <CaretDownIcon />
          </components.DropdownIndicator>
        );
      };

    return (
        <>
            {!isMobile ?
                <div className='posts-filter'>
                    <div className='container'>
                        <ul>
                            {_categories.map((specialty, index) => {
                                const {
                                    name,
                                    slug,
                                } = specialty

                                return (
                                    <li 
                                        key={index}
                                        onClick={() => setCatSelected(slug)}
                                        data-category={ slug }
                                        className={catSelected === slug ? 'is-selected' : ''}
                                        dangerouslySetInnerHTML={{ __html: name }}
                                    />
                                )
                            })}
                        </ul>
                    </div>
                </div>
                :
                <div className='mobile-post-filter'>
                    <Select
                        options={selectOptions}
                        onChange={handleCategoryChange}
                        className='react-select-container'
                        classNamePrefix='react-select'
                        components={{ DropdownIndicator }}
                        placeholder={'FILTER CATEGORIES'}
                        styles={selectStyle}
                        theme={(theme) => ({
                            ...theme,
                            colors: {
                            ...theme.colors,
                              primary25: '#fffafa',
                              primary: '#EC4834',
                            },
                          })}
                        instanceId={'99'}
                    />
                </div>
            }
        </>
    )
}
