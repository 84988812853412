import React, { useEffect, useState } from 'react'
import Button from 'components/button'

import { useIsMobile } from 'utils'

export default ({
    heading,
    copy,
    image,
    iframeUrl,
    forBlog
}) => {
    const isMobile = useIsMobile()

    return (
        <div className="interactive-map">
            <div className="container">
                <div className="columns">
                    {heading && copy &&
                    <div className="text column">
                        <h2 
                            dangerouslySetInnerHTML={{ __html: heading}}
                        />
                        <div className="copy">
                            <p dangerouslySetInnerHTML={{ __html: copy }} />
                        </div>
                    </div>
                    }{image &&
                    <div className="image column">
                        <div
                            className="image" 
                            style={{backgroundImage: "url(" + image.mediaItemUrl + ")"}}
                        />
                    </div>
                    }
                </div>
                { iframeUrl ?
                    isMobile ?
                        <div className="button-row">
                            <Button
                                url={iframeUrl}
                                label={'Open interactive directory'}
                                icon={`external-link`}
                            />
                        </div>
                    :
                    <div className={`iframe-container ${forBlog ? 'is-blog' : ''}`}>
                        <iframe src={iframeUrl} />
                    </div>
                : null }
            </div>
        </div>
    )
}