import React from 'react'

export default ({
    quote,
    attribution,
}) => {
    
    return (
        <div className="container">
            <blockquote dangerouslySetInnerHTML={{__html: quote }} />
            <cite dangerouslySetInnerHTML={{__html: attribution }} />
        </div>
    )
}
