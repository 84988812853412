import React, { useRef, useState } from 'react'

export default ({
    video,
    caption
}) => {

    const videoPlayerRef = useRef(null)
    const [videoPlaying, setVideoPlaying] = useState(false)

    const handleVideo = () => {
        if (videoPlayerRef.current.paused) {
            videoPlayerRef.current.play()
            setVideoPlaying(true)
        } else {
            videoPlayerRef.current.pause()
            setVideoPlaying(false)
        }
    }


    return (
        <div className={'single-video'}>
                <div className="container">
                    <div className="overlay-button" onClick={handleVideo}><img className={videoPlaying ? 'hide-play' : ''} src={require(`../images/play.png`)}/></div>
                    <video ref={ref => videoPlayerRef.current = ref}>
                        <source src={video.mediaItemUrl} type="video/mp4"/>
                    </video>
                    <p dangerouslySetInnerHTML={{ __html: caption }} />
                </div>
        </div>
    )
}
