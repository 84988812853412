import React, { useRef } from 'react'
import Slider from 'components/slider'
import Button from 'components/button'
import Link from 'components/link'

export default ({
    heading,
    resourcesList,
    resourcesSlider
}) => {
    const sliderRef = useRef(null)

    if(!resourcesList || !resourcesSlider){
        return null
    }

    return (
        <div className="resources-grid">
            <div className="container">
                <div className="heading-row">
                    <h2 dangerouslySetInnerHTML={{ __html: heading }} />
                </div>
                <div className="columns">
                    <div className="slider-container">
                        { typeof window != 'undefined' &&
                            <Slider
                                className={'carousel'}
                                options={{
                                    wrapAround: true,
                                    groupCells: 1,
                                    prevNextButtons: false,
                                    pageDots: true,
                                    adaptiveHeight: true,
                                    autoPlay: 7000
                                }}
                                flickityRef={ref => sliderRef.current = ref}
                            >
                                {resourcesSlider.map(({resource}, index) => {
                                    const {
                                        featuredImage,
                                        title,
                                        resourceFields
                                    } = resource

                                    const backgroundUrl = featuredImage && featuredImage.mediaItemUrl 

                                    if(!resourceFields.externalLink){
                                        return null
                                    }

                                    return (
                                        <div className="slide" key={index}>
                                            <Link to={resourceFields.externalLink} target="_blank">
                                                <div
                                                    className="image"
                                                    style={{
                                                        backgroundImage: `url(${ backgroundUrl })`
                                                    }}
                                                >
                                                    <div className={'progress'} />
                                                </div>
                                                <h3>
                                                    { title }
                                                </h3>
                                                <p>
                                                    { resourceFields.excerpt }
                                                </p>
                                            </Link>
                                            <Button 
                                                label={'Read more'}
                                                url={resourceFields.externalLink}
                                                target="_blank"
                                            />
                                        </div>
                                    )
                                })}
                            </Slider>
                        }
                    </div>
                    <div className="useful-resources-container">
                        <h4>
                            Useful resources
                        </h4>
                        <ul className="resources">
                            {resourcesList.map(({ resource }, index) => {
                                const {
                                    title,
                                    resourceFields,
                                } = resource
                                
                                if(!resourceFields.externalLink){
                                    return null
                                }

                                return (
                                    <li key={index}>
                                        <a href={resourceFields.externalLink} target="_blank">
                                            <p className="bold">
                                                { title }
                                            </p>
                                            <Button 
                                                label={'View resource'}
                                                icon={'arrow-up-right'}
                                            />
                                        </a>
                                    </li>
                                )
                            })}
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    )
}