import React, { useState, useRef, useEffect } from 'react'

export default ({
    submissionsData
}) => {
    const [translation, setTranslation] = useState(0)
    const [visibleSubmissions, setVisibleSubmissions] = useState([])

    let transitionInterval = null
    const transitionIntervalRef = useRef(transitionInterval)
    const translationRef = useRef(translation)
    const visibleItemsRef = useRef(6)

    transitionIntervalRef.current = transitionInterval
    translationRef.current = translation

    const getVisibleSubmissions = () => {
        const submissionsList = []

        const submissionsToShow = visibleItemsRef.current

        const _submissionsData = submissionsData.form_Submissions.nodes.filter(submission => submission.title.indexOf("Form Submission") === -1)

        if (_submissionsData) {
            let iterator = 0

            while (submissionsList.length < submissionsToShow) {
                if (!_submissionsData[iterator]) {
                    iterator = 0
                }

                const submission = _submissionsData[iterator]

                if (submission) {
                    const {
                        suburb
                    } = submission.formSubmissionFields

                    submissionsList.push({
                        quote: submission.title,
                        suburb
                    })
                }


                iterator++
            }
        }

        return submissionsList
    }

    useEffect(() => {
        const doVerticalSlider = () => {
            visibleItemsRef.current = visibleItemsRef.current + 2

            const _submissionsList = getVisibleSubmissions()

            setVisibleSubmissions(_submissionsList)
            setTranslation(translationRef.current - 90)
        }

        if (submissionsData && submissionsData.form_Submissions) {
            doVerticalSlider()

            if (!transitionIntervalRef.current) {
                transitionIntervalRef.current = setInterval(doVerticalSlider, 8000)
            }
        }

    }, [submissionsData])

    return (

        <div className={'submissions-list'}>
            <ul style={{
                transform: `translateY(${translation}px)`
            }}>
                {visibleSubmissions.map((submission, index) => {
                    const {
                        quote,
                        suburb
                    } = submission

                    return (
                        <li key={index}>
                            <div className="quote">
                                <p dangerouslySetInnerHTML={{ __html: `“${quote}”` }} />
                                <span className="suburb">
                                    {suburb}
                                    <img src={require(`../images/purple-heart.png`)} alt="Heart icon" />
                                </span>
                            </div>
                        </li>
                    )
                })}
            </ul>
        </div>
    )
}