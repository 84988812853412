import React from 'react'
import { useStaticQuery, graphql } from "gatsby"

import Button from 'components/button'
import BlogGridPost from 'components/blogGridPost'

export default ({
    title,
    link
}) => {

    const {nodes: posts } = useStaticQuery(graphql`
        query {
            siteFields {
                posts (
                    first: 4
                ) {
                    nodes {
                        date
                        title
                        content
                        categories {
                            nodes {
                                name
                            }
                        }
                        featuredImage {
                            caption
                            mediaItemUrl
                        }
                        link
                        postFields {
                            featuredPost
                        }
                    }
                }
            }
        }
    `).siteFields.posts

    return (
        <div className="main-grid-post">
            <div className="container">
                <section className="link-title">
                    <div className="left-content">
                        <h2 dangerouslySetInnerHTML={{ __html: title ? title : ''}} />
                        {link.title &&
                            <Button label={link.title} url={link.url} />
                        }
                    </div>
                </section>
                <section className="grid-content">
                    {posts.map((post, index) => {
                        return (
                            <li key={index}>
                                <BlogGridPost bg='yellow-bg' post={ post } postNumber={ index + 1 } button={ false } fullLink={ true } />
                            </li>
                        )
                    })}
                </section>
            </div>
        </div>
    )
}
