import React, { 
    useRef,
    useEffect
} from 'react'

import { useIsMobile } from 'utils'

import BottomBackground from '../images/hero-divider.png'

const { jarallax, jarallaxVideo } = typeof window !== "undefined" ? require("jarallax") : () => null

export default ({
   heading,
   copy,
   hashtags,
   backgroundVideo,
}) => {
    const heroContainerRef = useRef(null)
    const isMobile = useIsMobile()

    let className = "jarallax "

    if(backgroundVideo) {
        className += 'has-video'
    }

    useEffect(() => {
        if(heroContainerRef && heroContainerRef.current && backgroundVideo && !isMobile){
            jarallaxVideo()

            jarallax(heroContainerRef.current, {
                speed: 0.2,
                videoSrc: 'mp4:'+backgroundVideo.mediaItemUrl
            })
        }
    }, [heroContainerRef])

    return (
        <div 
            className={className} 
            ref={ref => heroContainerRef.current = ref}
        >
            { isMobile &&
                <div className={'mobile-video-bg'}>
                    <video preload="auto" data-setup='{}' autoPlay playsInline loop muted>
                        <source src={backgroundVideo && backgroundVideo.mediaItemUrl} type="video/mp4" />
                    </video>
                </div>
            }
            <div className="hero">
                <div className="container">
                    <div className="content">
                        <h1 
                            dangerouslySetInnerHTML={{ __html: heading}}
                        />
                        <p className="copy">
                            {copy}
                        </p>
                    </div>
                </div>
            </div>
            <div className="hero-bottom" style={{backgroundImage: "url(" + BottomBackground + ")" }}>
                <ul className="tags">
                    {hashtags && hashtags.map((tag, index) => {
                     return (
                        <li key={index}>
                            <img src={require(`../images/yellow-heart.png`)} alt="Heart icon"/>
                            <h4>
                                #{tag.hashtag}
                            </h4>
                        </li>
                     )
                    })}
                </ul>
            </div>
        </div>
    )
}