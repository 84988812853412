import React from 'react'

export default ({
    images,
}) => {

    const imageCount = images.length
    
    return (
        <div className="container">
            { images &&
                <ul>
                    { images.map((image, index) => {
                        return (
                            <li key={ index }>
                                <div style={{ backgroundImage: `url(${ image.image && image.image.mediaItemUrl })` }} />
                            </li>
                        )
                    })}
                </ul>
            }
            { images &&
                <div className="Images-captions">
                    { images.map((image, index) => {

                        let label = ''

                        if ( imageCount === 3 ) {
                            label = index === 0 ? 'Top Left:' : index === 1 ? 'Top Right:' : "Bottom:"
                        } else if ( imageCount === 2 ) {
                            label = index === 0 ? 'Left:' : "Right:"
                        } else {
                            label = "Above:"
                        }

                        return (
                            <div key={ index }>
                                {image.caption &&
                                <>
                                    <span dangerouslySetInnerHTML={{__html: label }} />
                                    {image.caption}
                                </>
                                }
                            </div>
                        )
                    })}
                </div>
            }
        </div>
    )
}
