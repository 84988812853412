import React, { useContext } from 'react'
import { MainContext } from 'context/context'
import Button from 'components/button'

export default ({
    title,
    copy,
    image,
    link,
    form
}) => {

    const { openModal } = useContext(MainContext)

    const openContact = () => {
        openModal({
            type: 'CONTACT',
            data: form
        })
    }

    return (
        <div className='single-cta'>
            <div className="container">
                { image &&
                    <div className='cta-image' style={{ backgroundImage: `url(${image.mediaItemUrl})` }} />
                }
                <div className="content">
                    <h3 dangerouslySetInnerHTML={{ __html: title }}/>
                    <div className='copy' dangerouslySetInnerHTML={{ __html: copy }}/>
                    <Button label={link.title} inverted onClick={openContact}/>
                </div>
            </div>
        </div>
    )
}
