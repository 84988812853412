import React, { useEffect, useState, useRef } from "react"
import Slider from 'components/slider'

import { useIsMobile } from 'utils'

export default ({
    heading,
    format,
}) => {
    const isMobile = useIsMobile

    const [images, setImages] = useState([])

    useEffect(() => {
        const instagramHandle = `lovemoreland`

        const request = new XMLHttpRequest()
        request.open('GET', 'https://www.instagram.com/explore/tags/' + instagramHandle + '/?__a=1', true)

        request.onload = () => {

            if (request.status >= 200 && request.status < 400) {
                if (!request.responseText.startsWith('<')) {

                    const data = JSON.parse(request.responseText)
                    const imageData = data.graphql.hashtag.edge_hashtag_to_media.edges

                    let _images = []

                    imageData.forEach(({ node: image }) => {
                        _images.push({
                            src: image.thumbnail_src,
                            url: `https://instagram.com/p/${ image.shortcode }`
                        })
                    })

                    _images = _images.slice(0, 25)

                    setImages(_images)
                }
            }
        }

        request.send()
    }, [])

    const flickityOptions = {
        wrapAround: true,
        prevNextButtons: true,
        pageDots: false,
        cellAlign: isMobile ? 'center' : 'left',
        autoPlay: 3000
    }

    return (
        <div className="instagram">
            <h2 dangerouslySetInnerHTML={{ __html: heading }} />
            <div className="container">
                { format === 'grid' ?
                    <div className="instagram-grid">
                        { images.map((image, index) => {
                            return (
                                <div className={'instagram-grid-item'} key={index}>
                                    <a href={image.url} target="_blank">
                                        <img src={image.src} alt="Instagram feed image" />
                                    </a>
                                </div>
                            )
                        })}
                    </div>

                :
                    <div className="slider-container">
                        { typeof window != 'undefined' &&
                            <Slider
                                className={'feed'}
                                options={flickityOptions}
                            >
                                { images.map((image, index) => {
                                    return (
                                        <div className={'slide'} key={index}>
                                            <a href={image.url} target="_blank">
                                                <img src={image.src} alt="Instagram feed image" />
                                            </a>
                                        </div>
                                    )
                                })}
                            </Slider>
                        }
                    </div>
                }
            </div>
        </div>
                            
    )
}