import React from 'react'

export default ({
   copy,
}) => {
    return (
        <div className="container">
            <div dangerouslySetInnerHTML={{ __html: copy }} />
        </div>
    )
}
