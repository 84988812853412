import React from 'react'
import { Link as ScrollLink } from "react-scroll"

export default ({
    chapterOptions
}) => {

    return (
        <div className='chapters'>
            {chapterOptions.map((chapter, index) => {
                const { options } = chapter
                const _option = options ? options.replace(' ', '-') : ''

                return (
                    <ScrollLink
                        key={ index }
                        to={ _option }
                        spy={ true }
                        smooth={ true }
                        offset={ -150 }
                        duration={ 1000 }
                    >
                        <p data-option={ _option }>{options}</p>
                    </ScrollLink>
                )
            })}
        </div>
    )
}
