import React, { useRef, useState, useEffect, useContext } from 'react'
import Slider from 'components/slider'
import { useStaticQuery, graphql } from "gatsby"
import { MainContext } from "context/context"
import BlogGridPost from 'components/blogGridPost'
import { useIsMobile } from 'utils'

export default ({
    title
}) => {

    const {
        catSelected
	} = useContext(MainContext)

    const isMobile = useIsMobile()

    const sliderRef = useRef(null)
    const sliderRef2 = useRef(null)

    const {nodes: posts } = useStaticQuery(graphql`
        query {
            siteFields {
                posts (
                    first: 50
                ) {
                    nodes {
                        date
                        title
                        content
                        categories {
                            nodes {
                                name
                            }
                        }
                        featuredImage {
                            caption
                            mediaItemUrl
                        }
                        link
                        postFields {
                            featuredPost
                        }
                    }
                }
            }
        }
    `).siteFields.posts

    let _featuredPosts = []

    {posts.map(post => {
        if (post.postFields.featuredPost === true) {
            _featuredPosts.push(post)
        }
    })}

    return (
        <div className={`featured-slider ${catSelected !== '' ? 'hide-slider' : ''}`}>
            <div className="container">
                <div className="heading-row">
                    {title &&
                    <h2 dangerouslySetInnerHTML={{ __html: title }} />
                    }
                </div>
                { !isMobile ?
                <div className="slider-container">
                    <div className="featured-slider-images">
                        <Slider
                            className={'featured-slider-images-slideshow'}
                            onChange
                            options={{
                                prevNextButtons: false,
                                pageDots: true,
                                fade: true,
                                imagesLoaded: true,
                            }}
                            flickityRef={ref => sliderRef.current = ref}
                        >
                            {_featuredPosts.map((data, index) => {
                                const {
                                    featuredImage,
                                } = data

                                let dummyCaption = 'caption'

                                return (
                                    <div className="featured-slider-image-cont" key={index}>
                                        <div className="featured-slider-image" style={{ backgroundImage: `url(${featuredImage && featuredImage.mediaItemUrl})`}} />
                                        {<div className={`featured-slider-image-caption ${!featuredImage.caption && 'no-caption'}`} dangerouslySetInnerHTML={{__html: featuredImage.caption || dummyCaption}} /> }
                                    </div>
                                )
                            })}
                        </Slider>
                    </div>
                    <div className="featured-slider-content">
                        <Slider
                            className={'featured-slider-content-slideshow'}
                            onChange
                            options={{
                                draggable: false,
                                prevNextButtons: false,
                                pageDots: false,
                                asNavFor: '.featured-slider-images-slideshow'
                            }}
                            flickityRef={ref => sliderRef2.current = ref}
                        >
                            {_featuredPosts.map((post, index) => {
                                return (
                                    <div className="content-section" key={index}>
                                        <BlogGridPost post={ post } postNumber={ index + 1 } button={ true } />
                                    </div>
                                )
                            })}
                        </Slider>
                    </div>
                </div>
                :
                <div className="slider-container">
                    <Slider
                        options={{
                            draggable: true,
                            prevNextButtons: false,
                            pageDots: true,
                            adaptiveHeight: true
                        }}
                    >
                        {_featuredPosts.map((post, index) => {
                            return (
                                <div className='mobile-slider'>
                                    <div className="featured-slider-images">
                                        <div className="featured-slider-image-cont" key={index}>
                                            <div className="featured-slider-images-slideshow">
                                                <div className="featured-slider-image" style={{ backgroundImage: `url(${post.featuredImage && post.featuredImage.mediaItemUrl})`}} />
                                                { post.featuredImage.caption && <div className="featured-slider-image-caption" dangerouslySetInnerHTML={{__html: post.featuredImage.caption}} /> }
                                            </div>
                                        </div>
                                    </div>
                                    <div className="featured-slider-content">
                                        <div className="content-section" key={index}>
                                            <BlogGridPost post={ post } postNumber={ index + 1 } button={ true } />
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                    </Slider>
                </div>
                }
            </div>
        </div>
    )
}