import React, { useState } from 'react'
import Ticker from 'react-ticker'
import PageVisibility from 'react-page-visibility'

export default ({
    suburbs
}) => {

    const [pageIsVisible, setPageIsVisible] = useState(true)

    const handleVisibilityChange = (isVisible) => {
      setPageIsVisible(isVisible)
    }
    
    return (
        <div className='news-scroll'>
            <PageVisibility onChange={handleVisibilityChange}>
                {pageIsVisible && (
                    <Ticker
                    speed={8}
                    height={35}
                    >
                        {({ index }) => (
                            <div className="marquee-container" id='marquee'>
                                {suburbs.map((location, index) => {
                                    const { area } = location
                                    
                                    return (
                                        <p key={index} dangerouslySetInnerHTML={{__html: area}}/>
                                    )
                                })}
                            </div>
                        )}
                    </Ticker>
                )}
            </PageVisibility>
        </div>
    )
}
