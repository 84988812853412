import React from "react"
import Slider from 'components/slider'
import { useStaticQuery, graphql } from "gatsby"

import { useIsMobile } from 'utils'

export default ({
    heading
}) => {
    const isMobile = useIsMobile

    const { nodes } = useStaticQuery(graphql`
        query {
            allInstagramContent(limit: 10) {
                nodes {
                    permalink
                    media_id
                    localImage {
                    absolutePath
                    childImageSharp {
                        fluid(maxHeight: 500, maxWidth: 500) {
                            src
                        }
                    }
                }
            }
        }
    }
    `).allInstagramContent

    const flickityOptions = {
        wrapAround: true,
        prevNextButtons: true,
        pageDots: false,
        cellAlign: isMobile ? 'center' : 'left',
        autoPlay: 3000
    }

    return (
        <div className="instagram">
            <h2 dangerouslySetInnerHTML={{ __html: heading }} />
            <div className="container">
                <div className="slider-container">
                    { typeof window != 'undefined' &&
                        <Slider
                            className={'feed'}
                            options={flickityOptions}
                        >
                            { nodes && nodes.map((item, index) => {
                                return (
                                    <div className={'slide'} key={index}>
                                        <a href={item.permalink} target="_blank">
                                            <img src={item.localImage.childImageSharp.fluid.src} alt="Instagram feed image" />
                                        </a>
                                    </div>
                                )
                            })}
                        </Slider>
                    }
                </div>
            </div>
        </div>
                            
    )
}