import React from 'react'

export default ({
    copy,
    featureFirstParagraph,
    chapterTitle
}) => {

    const _chapter = chapterTitle ? chapterTitle.replace(' ', '-') : ''
    
    return (
        <div className='Copy-cont'>
            <div className="container">
                <div id={_chapter} className={ featureFirstParagraph ? 'Copy-copy featureFirstParagraph' : 'Copy-copy' } dangerouslySetInnerHTML={{ __html: copy }} />
            </div>
        </div>
    )
}
